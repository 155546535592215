/* eslint-disable max-len */
export default {
  navbar: {
    aboutUs: 'About us',
    ourSolutions: 'Our solutions',
    ourProjects: 'Our projects',
    contactUs: 'Contact us',
    login: 'Login',
    fr: 'EN',
    en: 'FR',
    company: 'Company',
    projects: 'Projects',
    projectDetails: 'Project details',
    restoration: 'Restoration',
    legal: 'Legal',
    terms: 'Terms & Condition',
    privacy: 'Privacy Policy',
  },

  notFound: {
    pageNotFound: 'It seems you are lost!',
    goBackToHome: 'Go back to earth',
  },

  aboutUs: {
    introHeading: 'Climate action made simple.',
    introText1: 'You grow your shopify business.',
    introText2: 'We\'ll make it climate-positive, at no cost.',
    introButton: 'Take action',

    offersHeading: 'Your climate journey starts here.',

    offersCard1Title: 'Shopify app',
    offersCard1OneQ: 'Why should you care?',
    offersCard1OneA: 'Your customers want to shop sustainable. Our free app helps you take climate action and strengthen your brand, hassle-free.',
    offersCard1TwoQ: 'How does it works?',
    offersCard1TwoA: 'We add $1 at checkout to restore the planet\'s ecosystems. Our partner <1><0>Wildsense</0></1> monitors each project and sends you regular updates to share with your customers.',
    offersCard1Button: 'Get started',

    offersCard2Title: 'Carbon contribution',
    offersCard2OneQ: 'Why should you care?',
    offersCard2OneA: '86% of customers expect companies to take climate action. We help you stay ahead by going carbon positive.',
    offersCard2TwoQ: 'How does it works?',
    offersCard2TwoA: 'We source high-quality projects to offset the emissions you can\'t reduce and turn you into a sustainability champion.',
    offersCard2Button: 'Book a demo',

    expect1: 'Of customers expect companies',
    expect2: 'to take climate action.',

    promiseHeading: 'Our promise to you',

    promiseCard1Title: 'High quality projects',
    promiseCard1Text: 'We select community-led restoration projects based on their impact on biodiversity and local ecosystems. The carbon and co-benefits of each project are verified and monitored by <1><0>Wildsense</0></1>.',
    promiseCard2Title: 'Regular updates',
    promiseCard2Text: 'We prepare quarterly communication kits with updates of your projects, including the latest satellite imagery, as well as photos from the field. Engage with your customers by sharing with them their impact when shopping with you.',
    promiseCard3Title: '5-star support',
    promiseCard3Text: 'We\'re here to help you kickstart your climate journey. Expect quick response times from a cheerful team whenever you need us.',
    promiseButton: 'See our projects',

    promise90: 'To the projects',
    promise10: 'To keep the lights on',

    faqHeading: 'FAQ',

    faqCard1Title: 'Why offset?',
    faqCard1Text: 'Every year, we add fifty-one billion tons of greenhouse gases are added to atmosphere. Zero is what we need to aim for. It\'s up to each and everyone of us to do their part. Companies and individuals need to reduce their emissions as much as possible and make carbon contributions by building carbon sinks to balance the negative impacts they cannot get rid of.',
    faqCard2Title: 'Where does my money go?',
    faqCard2Text: '90% of your contribution goes to projects on the ground; the other 10% help us keep the lights on.',
    faqCard3Title: 'How do you select projects?',
    faqCard3Text: 'We select community-led restoration projects based on their impact on biodiversity and local ecosystems. The carbon and co-benefits of each project are verified and monitored by <1><0>Wildsense</0></1>.',
    faqCard4Title: 'Are you third-party verified?',
    faqCard4Text: 'We work with <1><0>Wildsense</0></1> to verify and monitor projects, using both satellite imagery and field data.',
    faqCard5Title: 'Are you able to certify my company carbon neutral?',
    faqCard5Text: 'We are currently building our own carbon calculator to help you assess your annual carbon footprint. If you already performed a carbon audit, we can help you make carbon contributions that match your emissions. We then deliver carbon contribution certificates you can share with your community!',
    faqCard6Title: 'I am an individual, can I offset my emissions with you?',
    faqCard6Text: 'We\'re currently building a carbon calculator dedicated for individuals and households. Click here to sign up to our newsletter to be the first one to know when this feature will be available!',
    faqCard7Title: 'How much will it cost me?',
    faqCard7Text: '- The plugin: The set up of the plugin is free of cost. You only need to transfer us the monthly amounts added by customers to their chart (1%). \n\n - Carbon positive: If you have not calculated your annual carbon footprint yet, we will guide you through the process. If you have already calculated your annual carbon footprint, you can choose to contribute to a meaningful reforestation project, equivalent to the amount of your emissions (or more if you like!). The costs will depend on your net emissions and desired projet.',

    talkHeading: 'Let\'s talk!',
    talkOne: 'Have questions?',
    talkTwo: 'Want help installing?',
    contactUs: 'Contact us',
  },

  ourProjects: {
    ourProjects: 'Our projects',
    eachProject: 'Each project is carefully selected for its impact on climate, biodiversity and local communities and verified by <1>Wildsense</1>. Click on the projects to find out more!',
    contribute: 'Contribute',
    comingSoon: 'Our projects are coming soon!',
    species: {
      mixed: 'Terrestrial Mixed forest',
      terrestrial: 'Terrestrial forest',
      tropical: 'Tropical forest',
      mangrove: 'Mangrove forest',
    },
    capacity: 'tons of carbon',
    backToProjects: 'Back to projects',
    whyWeLoveThisProject: 'Why we love this project',
    aboutTheCaregivers: 'About the caregivers',
    projectData: 'Project data',
    biomass: 'Biomass',
    biodiversity: 'Biodiversity',
    benefits: 'Benefits',
    projectMilestones: 'Project milestones',
    projectTimeline: 'Project timeline',
    download: 'Download',
  },

  contactUs: {
    make: 'Make ',
    green: 'green ',
    theNewNormal: 'the new normal',
    name: 'Your name',
    email: 'Your email',
    phone: 'Your phone number',
    chooseYourInterest: 'Choose your interest',
    add1: 'Add 1%',
    carbonNeutral: 'Go carbon neutral',
    message: 'Your message',
    write: 'Write your message here',
    send: 'Send',
    sent: 'Thank you for your message, we will get back to you shortly !',
    pleaseFullfilEachField: 'Please fullfil each field',
    invalidEmail: 'Invalid email',
    invalidPhone: 'Invalid phone number',
    invalidName: 'Invalid name',
    tooShort: 'Please write at least 10 characters',
    mention: 'The data transmitted with this form is processed by fix planet earth in order to manage your contact request.',
    link: 'Learn more about the management of your data and your rights.',
    invalid: 'Invalid {{field}}',
  },
}
