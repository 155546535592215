import React from 'react'
import { Box, Image } from '@chakra-ui/react'
import iconBurger from '../assets/icons/icon-burger.svg'

const MenuToggle = ({ toggle }: { toggle: any}) => (
  <Box display={{ base: 'block', md: 'none' }} onClick={toggle}>
    <Image src={iconBurger} boxSize={6} />
  </Box>
)

export default MenuToggle
