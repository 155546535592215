import React, { useEffect } from 'react'
import {
  Box,
  Image,
  Center,
  Text,
  Flex,
  Link,
  Spacer,
  useMediaQuery,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useLocation, Link as ReachLink } from 'react-router-dom'
import bcorp from '../assets/images/logo-bcorp-white.png'
import flag from '../assets/icons/french-flag.svg'

const Footer = () => {
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)')
  const location = useLocation()
  const { t } = useTranslation('', { keyPrefix: 'navbar' })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  if (location.pathname === '/projects') return null

  return (
    <Flex
      flexWrap="wrap"
      bgColor="brand.dark"
      px={[3, 8, '100px']}
      py="60px"
      direction={['column', 'column', 'row']}
    >
      <Flex flex="1" direction={['column', 'column', 'row']} alignItems={['center', 'center', 'start']}>
        <Box mb={[8, 8, 0]} mr={[0, 0, 8]}>
          <Text color="brand.neutral" fontSize="l" textAlign={['center', 'center', 'left']}>
            {t('company')}
          </Text>
          <Link
            textAlign={['center', 'center', 'left']}
            display="block"
            color="brand.neutral"
            as={ReachLink}
            to="/"
            fontWeight="700"
            marginTop={[0, 2]}
          >
            {t('aboutUs')}
          </Link>
          <Link
            textAlign={['center', 'center', 'left']}
            display="block"
            color="brand.neutral"
            as={ReachLink}
            to="/contact"
            fontWeight="700"
          >
            {t('contactUs')}
          </Link>
        </Box>
        <Box mb={[8, 8, 0]} mr={[0, 0, 8]}>
          <Text color="brand.neutral" fontSize="l" textAlign={['center', 'center', 'left']}>
            {t('projects')}
          </Text>
          <Link
            textAlign={['center', 'center', 'left']}
            display="block"
            color="brand.neutral"
            as={ReachLink}
            to="/projects"
            fontWeight="700"
            marginTop={[0, 2]}
          >
            {t('ourProjects')}
          </Link>
        </Box>
        <Box mb={[8, 8, 0]} mr={[0, 0, 8]}>
          <Text color="brand.neutral" fontSize="l" textAlign={['center', 'center', 'left']}>
            {t('legal')}
          </Text>
          <Link
            textAlign={['center', 'center', 'left']}
            display="block"
            color="brand.neutral"
            as={ReachLink}
            to="/legal/terms"
            fontWeight="700"
            marginTop={[0, 2]}
          >
            {t('terms')}
          </Link>
          <Link
            textAlign={['center', 'center', 'left']}
            display="block"
            color="brand.neutral"
            as={ReachLink}
            to="/legal/privacy"
            fontWeight="700"
          >
            {t('privacy')}
          </Link>
        </Box>
      </Flex>
      <Spacer flex={[0, 0, 0, 0, 0.5]} />
      <Center flex="0.5">
        <Image
          marginLeft={isSmallScreen ? 'initial' : 'auto'}
          marginRight={isSmallScreen ? '0px' : '2vw'}
          src={bcorp}
          boxSize="110px"
          fit="scale-down"
        />
      </Center>
      <Box flexBasis="100%" marginTop={[0, 4]} />
      <Text
        textAlign={isSmallScreen ? 'center' : 'initial'}
        order={isSmallScreen ? 2 : 'initial'}
        flex="1"
        color="brand.neutral"
      >
        Copyright ©
        {' '}
        {new Date().getFullYear()}
        {' '}
        fix planet earth. All rights reserved
      </Text>
      <Spacer flex={[0, 0, 0, 0, 0.5]} />
      <Box flex="0.5" textAlign={isSmallScreen ? 'center' : 'right'}>
        Made in France
        {' '}
        <Image display="inline" src={flag} h={4} />
      </Box>
    </Flex>
  )
}

export default Footer
