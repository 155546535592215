import React, { useState } from 'react'
import {
  Button, Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Image,
  Flex,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from 'i18next'
import { useNavigate } from 'react-router-dom'
import i18n from '../../translations/i18n'
import languageIcon from '../../assets/icons/icon-language.svg'
import languageIconWhite from '../../assets/icons/icon-language-white.svg'
import NavbarButton from './NavbarButton'

const DrawerMenu = ({ isOpen, onClose }: {isOpen:boolean, onClose:any}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('', { keyPrefix: 'navbar' })
  const [languageIconSrc, setLanguageIconSrc] = useState(languageIcon)

  const swapLanguage = () => {
    if (i18n.language.split('-')[0] === 'fr') { changeLanguage('en') } else { changeLanguage('fr') }
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody>
          <Flex direction="column" marginTop="40px" gap="20px">
            <NavbarButton
              navigate={() => {
                onClose()
                navigate('/')
              }}
              text={t('aboutUs')}
            />
            <NavbarButton
              text={t('ourProjects')}
              navigate={() => {
                onClose()
                navigate('/projects')
              }}
            />
            <NavbarButton
              text={t('contactUs')}
              navigate={() => {
                onClose()
                navigate('/contact')
              }}
            />
            {/* <Button
              color="brand.green"
              _hover={{ bg: 'brand.lightGreen', color: 'brand.white' }}
              _active={{ bg: 'brand.green', color: 'brand.white' }}
              onMouseOver={() => setLanguageIconSrc(languageIconWhite)}
              onMouseLeave={() => setLanguageIconSrc(languageIcon)}
              variant="ghost"
              size="md"
              onClick={swapLanguage}
              leftIcon={<Image src={languageIconSrc} h={6} pb={1} />}
            >
              {t(i18n.language.split('-')[0])}
            </Button> */}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerMenu
