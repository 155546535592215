import React from 'react'
import {
  Grid,
  GridItem,
  Image,
  Center,
  useDisclosure,
} from '@chakra-ui/react'
import logo from '../../assets/logos/logo-fpe.svg'
import MenuToggle from '../MenuToggle'
import DrawerMenu from './DrawerMenu'

const NavbarMobile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Grid
        templateColumns="repeat(3, 1fr)"
        px="8%"
        borderRadius="10px"
        alignItems="center"
        bg="brand.neutral"
        pos="fixed"
        margin={4}
        marginTop={6}
        left={0}
        right={0}
        boxShadow="lg"
        zIndex={15}
        onClick={onOpen}
      >
        <GridItem w="100%" h="40px" />
        <GridItem w="100%" h="40px">
          <Center h="40px">
            <Image src={logo} />
          </Center>
        </GridItem>
        <GridItem w="100%" h="40px">
          <Grid h="40px" alignContent="center" justifyItems="right">
            <MenuToggle toggle={() => {}} />
          </Grid>
        </GridItem>
      </Grid>
      <DrawerMenu isOpen={isOpen} onClose={onClose} />
    </>

  )
}

export default NavbarMobile
