/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { useMediaQuery, Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import Footer from './Footer'
import NavbarDesktop from './Navbar/NavbarDesktop'
import NavbarMobile from './Navbar/NavbarMobile'

const Layout = ({ children } : {children ?: any}) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)')

  return (
    <Box color="brand.white" bgColor="brand.neutralDeep">
      { isSmallScreen ? <NavbarMobile /> : <NavbarDesktop /> }
      <div style={{ minHeight: '100vh' }}>
        {children || <Outlet />}
      </div>
      <Footer />
    </Box>
  )
}

Layout.defaultProps = { children: undefined }

export default Layout
