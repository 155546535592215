/* eslint-disable max-len */
export default {
  navbar: {
    aboutUs: 'À propos',
    ourSolutions: 'Nos solutions',
    ourProjects: 'Nos projets',
    contactUs: 'Nous contacter',
    login: 'Se connecter',
    fr: 'EN',
    en: 'FR',
    company: 'Entreprise',
    projects: 'Projets',
    projectDetails: 'Détails du projet',
    legal: 'Légal',
    terms: 'Conditions générales d\'utilisation',
    privacy: 'Politique de confidentialité',
  },

  notFound: {
    pageNotFound: 'On dirait que quelqu\'un s\'est perdu.',
    goBackToHome: 'Retour sur Terre',
  },

  aboutUs: {
    introHeading: 'Participez à la lutte pour le climat',
    introText1: 'Vous gérez votre e-commerce,',
    introText2: 'on s\'occupe de votre impact.',
    introButton: 'Réserver une démo',

    offersHeading: 'Votre impact environmental commence ici',

    offersCard1Title: 'Plugin E-commerce',
    offersCard1OneQ: 'Pourquoi c\'est important ?',
    offersCard1OneA: 'Vos clients veulent faire des achats durables et responsables. Notre plugin vous aide à améliorer votre impact climatique tout en renforçant votre marque.',
    offersCard1TwoQ: 'Comment ca marche ?',
    offersCard1TwoA: 'Nous ajoutons 1% au montant du panier pour restaurer les écosystèmes naturels. Notre partenaire <1><0>Wildsense</0></1> surveille chaque projet et vous envoie des mises à jour régulières à partager avec vos clients.',
    offersCard1Button: 'C\'est parti !',

    offersCard2Title: 'Contribution carbone',
    offersCard2OneQ: 'Pourquoi c\'est important?',
    offersCard2OneA: 'Aujourd\'hui, plus de 86% des consommateurs attendent des marques qu\'elles s\'engagent et les aident à mieux consommer. Nous vous aidons vous engager et vous diriger vers un bilan carbone positif.',
    offersCard2TwoQ: 'Comment ça marche ?',
    offersCard2TwoA: 'Nous sélectionnons des projets de qualité pour compenser vos émissions et faire de vous un réel acteur du développement durable.',
    offersCard2Button: 'Réserver une démo',

    expect1: 'Des consommateurs attendent des marques ',
    expect2: 'qu’elles s’engagent et les aident à mieux consommer',

    promiseHeading: 'Nos engagements',

    promiseCard1Title: 'Uniquement des projets de qualité',
    promiseCard1Text: 'Nous sélectionnons des projets de restauration menés par des communautés locales qui bénéficient la biodiversité et les écosystèmes environnants. <1><0>Wildsense</0></1> s\'engage à vérifier la capture de carbone et autres bénéfices environnementaux pour chaque projet.',
    promiseCard2Title: 'Mises à jour régulières',
    promiseCard2Text: 'Nous vous préparons des communications trimestrielles avec des mises à jour de vos projets, ainsi que les dernières images satellites et des photos terrain. Partagez-les avec vos clients afin qu\'ils puissent estimer leur impact en réalisant leurs achats chez vous.',
    promiseCard3Title: 'Un suivi cinq étoiles',
    promiseCard3Text: 'Nous sommes là pour vous accompagner dans cette aventure. Notre équipe répondra à vos besoins rapidement et en tout temps.',
    promiseButton: 'Voir nos projets',

    promise90: 'Pour les projets',
    promise10: 'Pour vous assister',

    faqHeading: 'FAQ',

    faqCard1Title: 'Pourquoi s\'engager à une contribution carbone?',
    faqCard1Text: 'Chaque année, 51M de tonnes de gaz à effet de serre sont émises dans l\'atmosphère. Aujourd\'hui, l\'objectif à atteindre est celui de la neutralité carbone. Pour y parvenir, chacun doit faire sa part. Les entreprises et particuliers doivent non seulement réduire leurs émissions du mieux qu\'ils peuvent, mais aussi apporter une contribution positive en participant au développement de projets qui capturent des gaz à effet de serre, aussi appelés “puits carbone”.',
    faqCard2Title: 'Où va ma contribution ?',
    faqCard2Text: '90% de votre contribution va aux projets sur le terrain; le reste nous permet de rester en opération et vous assister.',
    faqCard3Title: 'Comment choisissez-vous vos projets ?',
    faqCard3Text: 'Nous sélectionnons des projets de restauration menés par des communautés locales qui bénéficient la biodiversité et les écosystèmes environnants. <1><0>Wildsense</0></1> s\'engage à vérifier la capture de carbone et autres bénéfices environnementaux pour chaque projet. Vous pouvez retrouver l\'ensemble de nos projets ici.',
    faqCard4Title: 'Êtes-vous vérifiés par une tierce partie ?',
    faqCard4Text: 'Nous collaborons avec <1><0>Wildsense</0></1> pour vérifier et contrôler les projets, en utilisant des données d\'imagerie satellite et des données terrain.',
    faqCard5Title: 'Pouvez-vous certifier la neutralité carbone de mon entreprise ?',
    faqCard5Text: 'Nous développons actuellement un calculateur carbone pour vous aider à évaluer votre empreinte carbone annuelle. Si votre bilan carbone est déjà réalisé, nous pouvons améliorer votre impact en vous proposant des projets de contribution carbone correspondant à vos émissions. Nous vous enverrons par la suite un certificat de neutralité carbone que vous pourrez partager avec votre communauté.',
    faqCard6Title: 'Je suis un particulier, puis-je compenser mes émissions avec vous ?',
    faqCard6Text: 'Nous élaborons actuellement un calculateur carbone dédié aux individus et ménages. Cliquez ici pour vous inscrire à notre newsletter et utiliser notre outil une fois disponible !',
    faqCard7Title: 'Combien cela va me coûter ?',
    faqCard7Text: '- Plugin : Les couts liés à l\'installation du plugin sont gratuits. Il suffit de nous transférer tous les mois les montants ajoutés par vos clients aux paniers (1 %) \n\n - Neutralité carbone : Si vous n\'avez pas calculé votre empreinte carbone annuelle, nous vous proposons de vous accompagner. Si vous avez déjà réalisé un bilan carbone annuel, vous choisissez de participer à un projet pour faire une contribution à hauteur de votre bilan, ou plus si vous le désirez. Les prix varieront en fonction de votre empreinte carbone ainsi que du projet choisi.',

    talkHeading: 'Discutons-en !',
    talkOne: 'Vous avez d\'autres questions ?',
    talkTwo: 'Vous ne savez pas par où commencer ?',
    contactUs: 'Contactez nous',
  },

  ourProjects: {
    ourProjects: 'Nos projets',
    eachProject: 'Chaque projet est soigneusement sélectionné pour son impact sur le climat, sa biodiversité, les communautés locales et vérifié par <1>Wildsense</1>. Cliquez sur un projet pour en savoir plus !',
    contribute: 'Contribuez',
    comingSoon: 'Nos projets arrivent bientôt !',
    species: {
      mixed: 'Forêt terrestre mixte',
      terrestrial: 'Forêt terrestre',
      tropical: 'Forêt tropicale',
      mangrove: 'Forêt de mangroves',
    },
    capacity: 'tonnes de carbone',
    backToProjects: 'Retour aux projets',
    whyWeLoveThisProject: 'Pourquoi nous aimons ce projet',
    aboutTheCaregivers: 'À propos des soignants',
    projectData: 'Informations sur le projet',
    biomass: 'Biomasse',
    biodiversity: 'Biodiversité',
    benefits: 'Avantages',
    projectMilestones: 'Accomplissements du projet',
    projectTimeline: 'Timeline du projet',
    download: 'Télécharger',
  },

  contactUs: {
    make: 'Sauvons le ',
    green: 'climat',
    theNewNormal: '',
    name: 'Votre nom',
    email: 'Votre adresse email',
    phone: 'Votre numéro de téléphone',
    chooseYourInterest: 'Choisissez votre interêt',
    add1: 'Ajouter 1%',
    carbonNeutral: 'Devenir neutre en carbone',
    message: 'Votre message',
    write: 'Ecrivez votre message ici',
    send: 'Envoyer',
    sent: 'Merci pour votre message, nous vous répondrons dans les plus brefs délais !',
    pleaseFullfilEachField: 'Merci de remplir tous les champs',
    invalidEmail: 'Email invalide',
    invalidPhone: 'Numéro de téléphone invalide',
    invalidName: 'Nom invalide',
    tooShort: 'Merci d\'écrire au moins 10 caractères',
    privacy: 'Politique de confidentialité',
    mention: 'Les données recueillies via ce formulaire sont traitées par fix planet earth afin de gérer votre demande de contact.',
    link: 'En savoir plus sur la gestion de vos données et vos droits.',
    invalid: '{{field}} invalide',
  },
}
