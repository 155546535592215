import React from 'react'
import ReactDOM from 'react-dom'
import {
  ChakraProvider,
  extendTheme,
} from '@chakra-ui/react'

import { BrowserRouter } from 'react-router-dom'

import Router from './Router'
import reportWebVitals from './reportWebVitals'
import './translations/i18n'
import './style/index.css'
import 'leaflet/dist/leaflet.css'

const theme = extendTheme({
  colors: {
    brand: {
      dark: '#172121',
      neutral: '#F7F4E8',
      green: '#8ABC94',
      lightGreen: '#A3C9A8',
      blue: '#74ABD2',
      lightDark: '#1B2828',
      neutralDeep: '#F0ECD9',
      white: '#FFFEF8',
    },
  },
  fonts: {
    heading: 'Comfortaa',
    body: 'Comfortaa',
    color: 'black',
  },
  components: { Button: { baseStyle: { borderRadius: '40px' } } },
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Router />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
