import React from 'react'
import { Button } from '@chakra-ui/react'

const NavbarButton = ({ text, navigate, props }: {text:string, navigate: any, props?: any}) => (
  <Button
    color="brand.dark"
    _hover={{ bg: 'brand.lightGreen' }}
    _active={{ bg: 'brand.green' }}
    variant="ghost"
    size="md"
    onClick={navigate}
    {...props}
  >
    {text}
  </Button>
)

NavbarButton.defaultProps = { props: undefined }

export default NavbarButton
