import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Spinner, Center } from '@chakra-ui/react'

import Layout from './components/Layout'

const AboutUs = lazy(() => import('./pages/AboutUs'))
const OurProjects = lazy(() => import('./pages/OurProjects'))
const ContactUs = lazy(() => import('./pages/ContactUs'))

const Terms = lazy(() => import('./pages/Policies/Terms'))
const Privacy = lazy(() => import('./pages/Policies/Privacy'))
const Cookies = lazy(() => import('./pages/Policies/Cookies'))

const NotFound = lazy(() => import('./pages/NotFound'))

const Spin = () => (
  <Center w="full" h="100vh">
    <Spinner
      thickness="6px"
      speed="0.8s"
      emptyColor="brand.green"
      color="brand.lightGreen"
      size="xl"
    />
  </Center>
)

const Router = () => (
  <Suspense fallback={<Spin />}>
    <Routes>
      <Route path="/legal" element={<Layout />}>
        <Route path="/legal/terms" element={<Terms />} />
        <Route path="/legal/privacy" element={<Privacy />} />
        <Route path="/legal/cookies" element={<Cookies />} />
      </Route>

      <Route path="/" element={<Layout />}>
        <Route path="/" element={<AboutUs />} />
        <Route path="/projects" element={<OurProjects />} />
        <Route path="/contact" element={<ContactUs />} />
      </Route>
      <Route path="*" element={<Layout><NotFound /></Layout>} />
    </Routes>
  </Suspense>
)

export default Router
