import React, { useState } from 'react'
import {
  Box,
  Flex,
  Image,
  Stack,
  Button,
  Spacer,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from 'i18next'
import { useNavigate } from 'react-router-dom'
import i18n from '../../translations/i18n'
import logo from '../../assets/logos/logo-fpe.svg'
import languageIcon from '../../assets/icons/icon-language.svg'
import languageIconWhite from '../../assets/icons/icon-language-white.svg'
import NavbarButton from './NavbarButton'

const Navbar = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('', { keyPrefix: 'navbar' })
  const swapLanguage = () => {
    if (i18n.language.split('-')[0] === 'fr') { changeLanguage('en') } else { changeLanguage('fr') }
  }

  const [languageIconSrc, setLanguageIconSrc] = useState(languageIcon)

  return (
    <Flex
      p="1"
      px="8%"
      borderRadius="10px"
      style={{
        backgroundColor: '#F7F4E8',
        marginTop: '20px',
        position: 'fixed',
        zIndex: 5,
        left: 0,
        right: 0,
        margin: 15,
        boxShadow: '0px 4px 9px -5px rgba(0, 0, 0, 0.11)',
      }}
    >
      <Box onClick={() => navigate('/')}>
        <Image src={logo} mt={1} />
      </Box>
      <Spacer />
      <Box>
        <Stack spacing={4} direction="row" align="center">
          <NavbarButton
            text={t('aboutUs')}
            navigate={() => navigate('/')}
          />
          <NavbarButton
            text={t('ourProjects')}
            navigate={() => navigate('/projects')}
          />
          <NavbarButton
            text={t('contactUs')}
            navigate={() => navigate('/contact')}
          />
          {/* <Button
            color="brand.green"
            _hover={{ bg: 'brand.lightGreen', color: 'brand.white' }}
            _active={{ bg: 'brand.green', color: 'brand.white' }}
            onMouseOver={() => setLanguageIconSrc(languageIconWhite)}
            onMouseLeave={() => setLanguageIconSrc(languageIcon)}
            variant="ghost"
            size="md"
            onClick={swapLanguage}
            leftIcon={<Image src={languageIconSrc} h={6} pb={1} />}
          >
            {t(i18n.language.split('-')[0])}
          </Button> */}
        </Stack>
      </Box>
    </Flex>
  )
}

export default Navbar
